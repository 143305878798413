/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

/**
 * Include the base styles for Angular Material core. We include this here so that you only
 * have to load a single css file for Angular Material in your app.
 */
@include mat.core();

$brand-primary-color: (50: #E3F2FD,
  100: #BBDEFB,
  200: #90CAF9,
  300: #64B5F6,
  400: #42A5F5,
  500: #4A86E8,
  600: #1E88E5,
  700: #1976D2,
  800: #1565C0,
  900: #0D47A1,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ));

/**
 * Define the palettes for your theme using the Material Design palettes available in palette.scss
 * (imported above). For each palette, you can optionally specify a default, lighter, and darker
 * hue.
 */
// $primary: mat.define-palette(mat.$blue-grey-palette, 700, 500, 900);
// $primary: mat.define-palette(mat.$light-blue-palette, 700, 300, 900);
$primary: mat.define-palette($brand-primary-color);
$accent: mat.define-palette(mat.$blue-grey-palette, A700, A200, A700);
$secondary: mat.define-palette(mat.$green-palette, A700, A200, A700);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette);

// Create theme (use mat-dark-theme for themes with dark backgrounds)
$dark-theme: mat.define-dark-theme($primary, $accent, $warn);


// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

/**
 * Include theme styles for core and each component used in your app.
 * Alternatively, you can import and @include the theme mixins for each component
 * that you are using.
 */
@include mat.all-component-themes($theme);

@mixin spacer-x-list {
  @for $i from 1 through 100 {
    .spacer-#{$i*5} {
      height: #{$i*5}px;
    }
  }
}

@include spacer-x-list;

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@mixin assign-value($value, $props...) {
  @each $prop in $props {
    #{$prop}: $value;
  }
}
