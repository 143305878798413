// @import 'mixins';
@import 'variables';

.opacity-50 {
  opacity: .5;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.spacer {
  flex: 1 1 auto;
}

.divider {
  display: block;
  margin: 10px 0;

  border-top: {
    width: 1px;
    style: solid;
    color: map-deep-get($colors, gray, light);
  }

  ;
  width: 100%;
}

$default-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
0 2px 2px 0 rgba(0, 0, 0, 0.14),
0 1px 5px 0 rgba(0, 0, 0, 0.12);

.box-shadow {
  border: 1px solid map-deep-get($colors, gray, light);
  background-color: map-deep-get($colors, white);
  border-radius: 3px;
  -webkit-box-shadow: $default-box-shadow;
  box-shadow: $default-box-shadow;
}

.icon-button {
  width: 40px;
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: map-deep-get($colors, gray, light);
    pointer-events: none;
  }
}
