// @import './themes/mixins';
// @import './themes/variables';
@import './themes/layout';
@import './themes/common';

html {
  margin: 0;
  min-height: 100%;

  body {
    margin: inherit;
    min-height: inherit;

    -webkit-overflow-scrolling: touch;

    // display: -webkit-flex;
    // -webkit-flex-direction: column;

    // display: flex;
    // flex-direction: column;

    background: #f3f3f3;
    margin: 0;
    // font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-family: map-deep-get($font, family);
    color: map-deep-get($colors, gray, default);
    overflow-y: scroll;
  }

  &.cdk-global-scrollblock {
    overflow-y: unset;

    // body {
      // overflow-y: scroll;
    // }
  }

}

html,
body {
  margin: 0;
  // height: 100%;
  min-height: 100%;
  // overflow: hidden;
  // overflow-y: visible;
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    width: auto !important;
  }
}

*[routerlinkactive] {
  outline: none;
}

.router-link {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.underline-hover:hover {
    text-decoration: underline;
  }

  &.disable {
    pointer-events: none;
    cursor: auto;
  }

}

.mat-toolbar {
  min-height: 48px;
  z-index: 997;

  // .mat-toolbar-row {
  // height: 48px;
  // }
}

.mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.quick-links {
  >* {
    text-align: center;

    margin: {
      top: 0;
      bottom: 0;
    }
  }

  margin: {
    top: 1em;
    bottom: 1em;
  }
}

.highlight-link {
  color: #0000ff !important;
}

.app-clickable {
  cursor: pointer;
  pointer-events: unset;
}

.app-snackbar {
  .mat-simple-snackbar {
    button {
      color: map-deep-get($colors, primary);
    }
  }
}

.profile-menu {
  // position: absolute;
  // top: 45px !important;
  // right: calc(100% - 100px) !important;
  width: 300px !important;
  // min-width: 300px !important;
  // max-width: 300px !important;

  -webkit-box-shadow:
    0 5px 3px rgba(0, 0, 0, 0.2),
    0 3px 1px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
  box-shadow:
    0 5px 3px rgba(0, 0, 0, 0.2),
    0 3px 1px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);

  .mat-menu-content {
    padding: 0px;

    .mat-toolbar {
      font-size: map-deep-get($font, size, header);
      padding: 10px;


      .mat-toolbar-row {
        height: 100%;
      }


      .profile-view-menu {
        padding: {
          top: 20px;
          bottom: 20px
        }

        img {
          height: 80px;
          width: 80px;
          border: 2px solid map-deep-get($colors, gray, light);
          color: map-deep-get($colors, gray, light);
          cursor: pointer;
          border-radius: 50%;
        }

        .name {
          font: {
            size: map-deep-get($font, size, title);
            weight: normal;
          }

          white-space: pre-wrap;
          text-align: center;
        }

        .school {
          white-space: nowrap;

          .university,
          .year {
            color: map-deep-get($colors, link);
            margin-left: 5px;
          }
        }

        .work {
          text-align: center;
          white-space: normal;
        }

      }


      .account-menu {

        .mat-list {
          width: 100%;

          h3 {
            text-align: center;
            font-size: map-deep-get($font, size, content);

            // background-color: map-deep-get($colors, accent);
            border: {
              bottom: 1px solid;
            }

            &.menu-item {
              cursor: pointer;
            }

            &:first-of-type {
              border-top: 1px solid;
            }
          }

          .mat-list-item {
            font-size: map-deep-get($font, size, header);
            cursor: pointer;

            &:hover {
              background-color: map-deep-get($colors, gray, light);
              // background-color: map-deep-get($colors, accent);
            }

            .user-profile {
              .mat-badge-content {
                right: -19px;
                top: -6px;
              }

            }
          }

        }

      }
    }

  }
}
